@import './global_variables.scss';
@import './effects.scss';

// Variables

body {
  cursor:
    url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8'?%3E %3Csvg id='Ebene_1' data-name='Ebene 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E %3Cdefs%3E %3Cstyle%3E .cls-1 %7B fill: rgba(237, 237, 237, .5); stroke: %23f6f6f6; stroke-miterlimit: 10; stroke-width: .87px; %7D %3C/style%3E %3C/defs%3E %3Ccircle class='cls-1' cx='8' cy='8' r='8'/%3E %3C/svg%3E")
      8 8,
    auto;
  margin: 0;
  font-size: 12pt;
  background-color: $dark-color;
  color: $light-color;

  &::before {
    content: '';
    position: absolute;
    transform: translate(-50%, -50%) scale(2);
    top: 0;
    left: 30%;
    right: 0;
    bottom: 0;
    //background-image: radial-gradient(circle, $dark-color 0%, $dark-color 50%);
    opacity: 0.5;
  }

  h1 {
    font-size: 2em;
    font-weight: 700;
    font-variation-settings: 'slnt' 0;
    color: $light-color;
    margin: 0;
  }

  p {
    font-size: 2em;
    font-weight: 200;
    font-variation-settings: 'slnt' 0;
    margin: 0;
  }

  .text-standard {
    font-size: 2em;
    font-weight: 200;
    font-variation-settings: 'slnt' 0;
    color: $light-color;
    margin: 0;
  }

  .text-framed {
    @include framedText;
  }
}

header {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
}

.clickMe {
  position: absolute;
  top: 0;
  @include glass;
  @include morph(100%);
  padding: 45px 40px;
}

.header-component {
  @include glass;
  width: fit-content;
  position: relative;
  display: flex;
  width: 80%;
  height: 80%;
  flex-direction: column;

  .mainInfo {
    margin: 60px;
    display: flex;
    flex-direction: column;
    width: calc(100%-padding * 2-margin * 2);
    height: 100%;
    align-items: flex-start;
    justify-content: flex-end;
  }
}

.square {
  content: '';
  position: absolute;
  border: 10px solid red;
  transform: rotateZ(40deg) scale(4) skewY(-20deg);
  border-radius: 30px;
  height: 120px;
  width: 120px;
  overflow: hidden;
}

#square-red {
  border-color: red;
  box-shadow:
    0 0 30px red,
    inset 0 0 20px red;
  top: 0;
  left: 20vw;
}

#square-blue {
  border-color: yellow;
  box-shadow:
    0 0 30px yellow,
    inset 0 0 20px yellow;
  top: 100vh;
  left: 70vw;
}

#clipped {
  --myWidth: 100vw;
  --myHeight: 10rem;
  --myRad: 2rem;
  clip-path: url(#clipped);
  background-color: aqua;
}

#glassShape {
  $cornerRadius: 50;
  $glassWidth: 1350;
  $glassHeight: 500;
  $indentWidth: 150;
  $indentHeight: 30;
  $indentLowerRoundness: 50;
  $indentUpperRoundness: 35;

  path {
    stroke: red;
    fill: none;
    d: path(
      'M ' + $cornerRadius + ',0
    h ' + ($glassWidth - 2 * $cornerRadius) + '
            s ' +
        $cornerRadius + ',0 ' + $cornerRadius + ',' + $cornerRadius + '
    v +' +
        ($glassHeight - 2 * $cornerRadius) + '
    s 0, ' + $cornerRadius + ' -' + $cornerRadius +
        ',' + $cornerRadius + '
    h -' + ((($glassWidth - 2 * $cornerRadius) - $indentWidth)/2) +
        '
    c -' + $indentLowerRoundness + ', 0 -'+ ($indentWidth/2-$indentUpperRoundness)+', -' + $indentHeight +
        ' -' + $indentWidth/2 + ',-' + $indentHeight + ' 
    c -' + $indentUpperRoundness + ', 0 -' +
        (($indentWidth/2)-$indentLowerRoundness) + ', ' + $indentHeight + ' -' + $indentWidth/2 + ',' +
        $indentHeight + '
    h -' + ((($glassWidth - 2 * $cornerRadius) - $indentWidth)/2) + '
    s -' +
        $cornerRadius + ', 0 -' + $cornerRadius + ', -' + $cornerRadius + '
    v -' + (
          $glassHeight - 2 * $cornerRadius
        ) + '
            s 0, -' + $cornerRadius + ' ' + $cornerRadius + ',-' + $cornerRadius
    );
  }
}
