@import './global_variables.scss';

// Box shadows
@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin morph($border-radius: 0.5em) {
  $x: 1.95px;
  $y: 1.95px;
  position: relative;
  border: none;
  border-radius: $border-radius;
  display: inline-block;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
  box-shadow:
    $x $y 2.6px rgba($shadow-color-dark, 0.3),
    -1.5px -1.5px 2.4px rgba($shadow-color-light, 1);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    box-shadow:
      inset $x $y 2.6px rgba($shadow-color-dark, 0.3),
      inset -1.5px -1.5px 2.4px rgba($shadow-color-light, 1);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &.active {
    box-shadow: none;

    &::before {
      opacity: 1;
    }
  }
}

@mixin glass($border-radius: 1.9em) {
  background: rgba(255, 255, 255, 0.027);
  border-radius: $border-radius;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.5);
}

@mixin framedText($padding: 0.2em) {
  color: $dark-color;
  background: $light-color;
  padding: 0 $padding;
  border-radius: 10px;
  font-weight: 900;
}
