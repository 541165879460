@import '~bootstrap/scss/bootstrap';

// Main colors

$dark-color: #070707;
$light-color: #f7f7f7;
$accent-color: #f5b553;
$accent-color-light: #f7deb8;
$accent-color-dark: #854836;

$shadow-color-dark: yellow;
$shadow-color-light: red;

@font-face {
  font-family: 'Inter';
  font-weight: 100 1000;
  font-variation-settings: 'slnt' 0 -10;
  src: url('./assets/fonts/Inter-VariableFont_slnt,wght.ttf') format('woff2-variations');
  src: url('./assets/fonts/Inter-VariableFont_slnt,wght.ttf') format('woff2') tech('variations');
}

html,
body {
  font-family: 'Inter', sans-serif;
}
